import React from 'react'
import './footer.css'

const Footer = () => {
    return (
        <footer id='footer'>
            <a href="#" className='footer__logo'>Sujal Timilsina</a>
            <ul className='permalinks'>
                <li>
                    <div className='footer__logo__hover'>
                        <a href="#">Home</a>
                    </div>
                </li>
                <li>
                    <div className='footer__logo__hover'>
                        <a href="#about">About</a>
                    </div>
                </li>
                <li>
                    <div className='footer__logo__hover'>
                        <a href="#experience">Experience</a>
                    </div>
                </li>
                <li>
                    <div className='footer__logo__hover'>
                        <a href="#services">Services</a>
                    </div>
                </li>
                <li>
                    <div className='footer__logo__hover'>
                        <a href="#portfolio">Portfolio</a>
                    </div>
                </li>
            </ul>
            <div className="footer__socials">
                <a href="https://www.linkedin.com/in/sujal-timilsina-403b8b202/" target='_blank' rel="noopener noreferrer">Connect with me on Linkedin</a>
                {/* <a href="https://www.youtube.com/" target='_blank' rel="noopener noreferrer">Social 1</a>
                <a href="https://www.youtube.com/" target='_blank' rel="noopener noreferrer">Social 1</a> */}
            </div>

            <div className="footer__copyright">
                <small>&copy; Sujal Timilsina: All rights reserved.</small>
            </div>
        </footer>
    )
}

export default Footer