import React from 'react'
import './about.css'
import ME from '../../assets/About-me.jpg'
// import { FaAward } from "react-icons/fa";
import { LuUsers } from "react-icons/lu";
import { LuFolderKanban } from "react-icons/lu";
import { IoHomeOutline } from "react-icons/io5";


const About = () => {
    return (
        <section id='about'>
            <h5>Get To Know</h5>
            <h2>About Me</h2>
            <div className="container about__container">
                <div className="about__me">
                    <div className="about__me-image">
                        <img src={ME} alt="About Image" />


                    </div>
                </div>
                <div className="about__content">
                    <div className="about__cards">
                        <article className='about__card'>
                            <IoHomeOutline className='about__icon' />
                            <h5>Home</h5>
                            <small>Boston, MA. Born Kathmandu, Nepal. </small>
                            {/*Use home icon here */}
                        </article>

                        <article className='about__card'>
                            <LuUsers className='about__icon' />
                            <h5>Favorite Activities</h5>
                            <small>Volleyball/Hiking</small>
                        </article>

                        <article className='about__card'>
                            <LuFolderKanban className='about__icon' />
                            <h5>Academic Intrest</h5>
                            <small>Machine Learning/AI</small>
                        </article>
                    </div>

                    <p>
                        Hello, welcome to my portfolio website! I completed my bachelors in Computer Science
                        from Purdue University and am currently pursuing a masters in Computer Science with a
                        focus in AI at UMass Amherst. A lot of the experience and work I have done has revolved
                        around lower level programming and fullstack development so as I pursue my masters, my focus
                        will be around Artifical Intelligence, particularly understanding the theory behind a lot of
                        the Machine Learning models. However ultimately, my goal is to broaden my knowledge in different
                        fields of Computer Science and be an all around solid programmar with a variety of skillsets that
                        I can utilize.
                    </p>

                    {/* Change the icons here */}
                    <a href="#experience" className='btn btn-primary'>See My Experiences</a>

                </div>
            </div>
        </section>
    )
}

export default About
