import React from 'react'
import './experience.css'
import { FaCheckCircle } from "react-icons/fa";



const Experience = () => {
    return (
        <section id='experience'>
            <h5>What skills I have</h5>
            <h2>My Experience</h2>

            <div className="container experience__container">
                <div className="experience__frontend">
                    <h3>Most Used Languages Over The Years</h3>
                    <div className="experience__content">
                        <article className='experience__details'>
                            <FaCheckCircle className='experience__details-icon' />
                            <div className='experience__text'>
                                <h4>Python</h4>
                                <small className='text-light'>4+ years</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <FaCheckCircle className='experience__details-icon' />
                            <div className='experience__text'>
                                <h4>Java</h4>
                                <small className='text-light'>4+ years</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <FaCheckCircle className='experience__details-icon' />
                            <div className='experience__text'>
                                <h4>C</h4>
                                <small className='text-light'>3+ years</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <FaCheckCircle className='experience__details-icon' />
                            <div className='experience__text'>
                                <h4>JavaScript</h4>
                                <small className='text-light'>1+ year</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <FaCheckCircle className='experience__details-icon' />
                            <div className='experience__text'>
                                <h4>Dart</h4>
                                <small className='text-light'>1+ year</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <FaCheckCircle className='experience__details-icon' />
                            <div className='experience__text'>
                                <h4>Bash</h4>
                                <small className='text-light'>1+ year</small>
                            </div>
                        </article>
                    </div>
                </div>
                {/* END OF FRONTEND */}
                <div className="experience__backend">
                    <h3>Most Used Tools/Frameworks Over The Years</h3>
                    <div className="experience__content">
                        <article className='experience__details'>
                            <FaCheckCircle className='experience__details-icon' />
                            <div className='experience__text'>
                                <h4>Django</h4>
                                <small className='text-light'>2+ years</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <FaCheckCircle className='experience__details-icon' />
                            <div className='experience__text'>
                                <h4>Tailwind</h4>
                                <small className='text-light'>2+ years</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <FaCheckCircle className='experience__details-icon' />
                            <div className='experience__text'>
                                <h4>Flutter</h4>
                                <small className='text-light'>1+ years</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <FaCheckCircle className='experience__details-icon' />
                            <div className='experience__text'>
                                <h4>SQL/NoSQL</h4>
                                <small className='text-light'>1+ years</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <FaCheckCircle className='experience__details-icon' />
                            <div className='experience__text'>
                                <h4>React</h4>
                                <small className='text-light'>1+ years</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <FaCheckCircle className='experience__details-icon' />
                            <div className='experience__text'>
                                <h4>Android Studio</h4>
                                <small className='text-light'>1+ years</small>
                            </div>
                        </article>
                    </div>
                </div>
                {/* END OF BACKEND */}
            </div>



        </section>


    )
}

export default Experience
