import React, { useState } from 'react';
import './portfolio.css';
import IMG1 from '../../assets/JudgeMePic.png';
import IMG2 from '../../assets/HomieTime.png';
import IMG3 from '../../assets/NasaApp.png';
import IMG4 from '../../assets/portfolio4.jpg';
import IMG5 from '../../assets/portfolio5.png';
import IMG6 from '../../assets/portfolio6.jpg';

const data = [
    {
        id: 1,
        image: IMG1,
        title: 'JudgeMe',
        github: '',
        demo: 'https://youtu.be/_JKAqM9KQRE',
    },
    {
        id: 2,
        image: IMG2,
        title: 'HomeTime',
        github: '',
        demo: 'https://youtu.be/6KPBY3rBWBk',
    },
    {
        id: 3,
        image: IMG3,
        title: 'Nasa App',
        github: '',
        demo: 'https://youtu.be/g8Upp_jTPmc',
    },
    // {
    //     id: 4,
    //     image: IMG4,
    //     title: 'Shell/Malloc',
    //     github: '',
    //     demo: '',
    // },
    // {
    //     id: 5,
    //     image: IMG5,
    //     title: 'System Monitor',
    //     github: '',
    //     demo: '',
    // },
    // {
    //     id: 6,
    //     image: IMG6,
    //     title: 'Research Paper',
    //     github: '',
    //     demo: '',
    // }
]

const Popup = ({ message, onClose }) => {
    return (
        <div className="popup">
            <div className="popup-content">
                <p>{message}</p>
                <button onClick={onClose} className="close__btn">Close</button>
            </div>
        </div>
    );
};

const Portfolio = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');

    const handleDemoClick = (id, demo) => {
        if (id === 4 || id === 5 || id === 6) {
            setPopupMessage("The feature is currently unavailable and is coming soon. I apologize for the inconvenience!");
            setShowPopup(true);
        } else {
            window.open(demo, '_blank', 'noopener,noreferrer');
        }
    };

    return (
        <section id='portfolio'>
            <h5>My Recent Work</h5>
            <h2>Portfolio</h2>
            <div className="container portfolio__container">
                {
                    data.map(({ id, image, title, github, demo }) => {
                        return (
                            <article className='portfolio__item' key={id}>
                                <div className="portfolio__item-image">
                                    <img src={image} alt={title} />
                                </div>
                                <h3>{title}</h3>
                                <div className="portfolio__item-cta">
                                    <button
                                        className='btn btn-primary'
                                        onClick={() => handleDemoClick(id, demo)}
                                    >
                                        Live Demo
                                    </button>
                                </div>
                            </article>
                        );
                    })
                }
            </div>
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
        </section>
    );
};

export default Portfolio;