import React from 'react';
import './bubble.css';

const Bubble = ({ color, position, size, delay }) => {
    const style = {
        backgroundColor: color,
        width: size,
        height: size,
        position: 'fixed',
        ...position,
        animationDelay: delay
    };

    return <div className="bubble" style={style}></div>;
}

export default Bubble;
