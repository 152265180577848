import React from 'react'
import './service.css'
// import { FaRegCircleCheck } from "react-icons/io";
import { FaRegCircleCheck } from "react-icons/fa6";


const Service = () => {
    return (
        <section id='services'>
            <h5>My Knowledge</h5>
            <h2>Classes-Work-Extracurriculars</h2>
            <div className="container services__container">
                <article className='service'>
                    <div className="service__head">
                        <h3>Important Classes</h3>
                    </div>
                    <ul className='service__list'>
                        <li>
                            <div className="icon-text-wrapper">

                                <FaRegCircleCheck className='service__list-icon' />
                                <p>Foundations of Computer Science</p>

                            </div>
                        </li>
                        <li>
                            <div className="icon-text-wrapper">
                                <FaRegCircleCheck className='service__list-icon' />
                                <p>Programming in C</p>

                            </div>
                        </li>
                        <li>
                            <div className="icon-text-wrapper">
                                <FaRegCircleCheck className='service__list-icon' />
                                <p>Data Structures and Algorthims</p>
                            </div>
                        </li>
                        <li>
                            <div className="icon-text-wrapper">
                                <FaRegCircleCheck className='service__list-icon' />
                                <p>Computer Architecture</p>
                            </div>
                        </li>
                        <li>
                            <div className="icon-text-wrapper">
                                <FaRegCircleCheck className='service__list-icon' />
                                <p>Systems Programming</p>
                            </div>
                        </li>
                        <li>
                            <div className="icon-text-wrapper">
                                <FaRegCircleCheck className='service__list-icon' />
                                <p>Intro to Adv. Algorthim Analysis</p>
                            </div>
                        </li>
                        <li>
                            <div className="icon-text-wrapper">
                                <FaRegCircleCheck className='service__list-icon' />
                                <p>Operating Systems</p>
                            </div>
                        </li>
                        <li>
                            <div className="icon-text-wrapper">
                                <FaRegCircleCheck className='service__list-icon' />
                                <p> Info Systems and Databases</p>
                            </div>
                        </li>
                    </ul>
                </article>
                {/* END OF UI/UX */}
                <article className='service'>
                    <div className="service__head">
                        <h3>Work Experience</h3>
                    </div>
                    <ul className='service__list'>
                        <li>
                            <div className="icon-text-wrapper">
                                <FaRegCircleCheck className='service__list-icon' />
                                <p>Software Engineer - GE Appliances</p>
                            </div>
                        </li>
                        <li>
                            <div className="icon-text-wrapper">
                                <FaRegCircleCheck className='service__list-icon' />
                                <p>Software Developer - KeyByte LLC</p>
                            </div>
                        </li>
                        <li>
                            <div className="icon-text-wrapper">
                                <FaRegCircleCheck className='service__list-icon' />
                                <p>Teaching Assistant - Python Programming</p>
                            </div>
                        </li>
                        <li>
                            <div className="icon-text-wrapper">
                                <FaRegCircleCheck className='service__list-icon' />
                                <p>Teaching Assistant - Pebblst Project</p>
                            </div>
                        </li>
                        <li>
                            <div className="icon-text-wrapper">
                                <FaRegCircleCheck className='service__list-icon' />
                                <p>Research Assistant: Cloud Computing</p>
                            </div>
                        </li>
                        <li>
                            <div className="icon-text-wrapper">
                                <FaRegCircleCheck className='service__list-icon' />
                                <p>Software Developer: Merck</p>
                            </div>
                        </li>
                    </ul>
                </article>
                {/* END OF Web Development */}
                <article className='service'>
                    <div className="service__head">
                        <h3>Miscellaneous</h3>
                    </div>
                    <ul className='service__list'>
                        <li>
                            <div className="icon-text-wrapper">
                                <FaRegCircleCheck className='service__list-icon' />
                                <p>Purdue Men's Club Volleyball</p>
                            </div>
                        </li>
                        <li>
                            <div className="icon-text-wrapper">
                                <FaRegCircleCheck className='service__list-icon' />
                                <p>Data Mine Learning Community</p>
                            </div>
                        </li>
                        <li>
                            <div className="icon-text-wrapper">
                                <FaRegCircleCheck className='service__list-icon' />
                                <p>Dean's List all 4 years</p>
                            </div>
                        </li>
                        <li>
                            <div className="icon-text-wrapper">
                                <FaRegCircleCheck className='service__list-icon' />
                                <p>Jean E. Rubin Scholarship</p>
                            </div>
                        </li>
                        <li>
                            <div className="icon-text-wrapper">
                                <FaRegCircleCheck className='service__list-icon' />
                                <p>Purdue Alumni General Scholarship</p>
                            </div>
                        </li>
                        <li>
                            <div className="icon-text-wrapper">
                                <FaRegCircleCheck className='service__list-icon' />
                                <p>Boston Herald All-Scholastic in Volleyball</p>
                            </div>
                        </li>
                    </ul>
                </article>
                {/* END OF Content Creation*/}

            </div>
        </section>

    )
}

export default Service