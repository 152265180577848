import React from 'react'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Experience from './components/experience/Experience'
import Service from './components/services/Service'
import Portfolio from './components/portfolio/Portfolio'
// import Testimonials from './components/Testimonials/Testimonials'
// import Contacts from './components/contacts/Contacts'
import Footer from './components/footer/Footer'
import Bubble from './Bubble'

const App = () => {
    return (
        <>
            <Bubble color="var(--color-primary-variant)" size="200px" position={{ top: '10%', left: '15%' }} delay="0s" />
            <Bubble color="var(--color-primary-variant)" size="150px" position={{ bottom: '20%', right: '20%' }} delay="3s" />
            <Bubble color="var(--color-primary-variant)" size="100px" position={{ top: '30%', right: '10%' }} delay="3s" />
            <Header />
            <Nav />
            <About />
            <Experience />
            <Service />
            <Portfolio />
            {/* <Testimonials />
            <Contacts /> */}
            <Footer />
        </>
    )
}

export default App
